import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ismemberofdescendant"
    }}>{`isMemberOfDescendant`}</h1>
    <p>{`This function is similar to `}<inlineCode parentName="p">{`isMemberOf`}</inlineCode>{` with the exception that it will return true
if the user is a member of any team which is a child of the named team. This function
only supports first level parents. For example given the team `}<inlineCode parentName="p">{`'Parent::Child::Grandchild'`}</inlineCode>{`,
checking for `}<inlineCode parentName="p">{`'Child'`}</inlineCode>{` will be false.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

Utils.isMemberOfDescendant(user, 'Parent'); // Returns true if the user is a member of a 'Parent::' team.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      